import { LOG_LEVEL, loadTranslation } from '@b2x/core/bundle';
import { initializeStore } from '@seamless/store';

import { handleOpenUserMenuOnAlert } from './handlers/alert-user-menu';
import { handleNavigationItem } from './handlers/navigation-item';
import { LOG_GROUPS, logger } from './utilities/logger';
import { buildTime, version } from './utilities/version';

async function bootstrap() {
  await loadTranslation('a6b17e3617922fdb78965d810593d828');
  const store = initializeStore();

  logger(`One Header Integrator ${version} - ${buildTime}`, {
    args: [],
    group: LOG_GROUPS.BOOTSTRAP,
    level: LOG_LEVEL.INFO,
  });

  /**
   * Initialize need to be sure that the HeaderConnection already exist
   * or we will dispatch to it even before the existence of it and it will be lost.
   */
  const subscription = store.subscribe('HP_HEADER_CONNECTION', async () => {
    try {
      handleNavigationItem();
      await handleOpenUserMenuOnAlert();
    } catch (error) {
      logger('Error while integrating OneHeader with User Menu', {
        args: [error],
        group: LOG_GROUPS.BOOTSTRAP,
        level: LOG_LEVEL.WARN,
      });
    }

    subscription.unsubscribe();
  });
}

bootstrap();

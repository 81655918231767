import { CONNECTIONS, LOG_LEVEL } from '@b2x/core/bundle';

import type { AuthenticationDispatchers } from '@b2x/authentication-library';

import { getDispatchers } from '../utilities/common';
import { LOG_GROUPS, logger } from '../utilities/logger';

export const handleOpenUserMenuOnAlert = async () => {
  const { alert } = await getDispatchers<AuthenticationDispatchers>(CONNECTIONS.AUTH_CONNECTION_NAME);

  alert(() => {
    document.dispatchEvent(
      new CustomEvent('hp-header:open-specific-flyout', {
        detail: {
          name: 'iam-user-menu-v3',
        },
      }),
    );

    logger('An alert was found. Opening the user menu', {
      args: [],
      group: LOG_GROUPS.ALERT_USER_MENU,
      level: LOG_LEVEL.INFO,
    });
  });
};

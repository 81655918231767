import { getConnectionDispatchers } from '@b2x/core/bundle';
import { initializeStore } from '@seamless/store';

import type { AuthenticationState } from '@b2x/authentication-library';
import type { Subscription } from '@seamless/store';

export const addUnloadEvent = (subscription: Subscription) => {
  window.addEventListener('pagehide', () => {
    subscription.unsubscribe();
  });
  window.addEventListener('unload', () => {
    subscription.unsubscribe();
  });
};

export const getConnectionState = async (connectionName: string): Promise<AuthenticationState> => {
  const store = initializeStore();

  return new Promise<AuthenticationState>((resolve, reject) => {
    const timeout = setTimeout(() => {
      reject(new Error(`Timeout reached while resolving ${connectionName}`));
    }, 30000);
    const subscription = store.subscribe(connectionName, (state: AuthenticationState) => {
      if (!state.isPending) {
        resolve(state);
        clearTimeout(timeout);
      }
    });
    addUnloadEvent(subscription);
  });
};

export const getDispatchers = async <T extends object>(connectionName: string) => {
  const dispatchers = await getConnectionDispatchers<T>(connectionName, 30000);

  if (Object.keys(dispatchers).length === 0) {
    throw new Error(`No ${connectionName} dispatchers found`);
  }

  return dispatchers;
};

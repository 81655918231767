import { getComponentRegistryConfig, useTranslations } from '@b2x/core/bundle';

import type { Profile } from '@b2x/profile-library';
import type { HeaderNavigationItemsConfiguration } from '@dh-io-hp/header-connection';
import type { ComponentsState } from '@owc/component-registry-connection';

interface UserMenuComponentConfig extends ComponentsState {
  payload?: {
    loginLabel?: string;
    userProfileAria?: string;
  };
}

export const getLoginLabelFromUserMenuConfig = async () => {
  const [userMenuConfig] = await getComponentRegistryConfig<Partial<UserMenuComponentConfig[]>>((components) => {
    const registry = components.find((component) => component?.payload?.userProfileAria);
    return registry ? [registry] : [];
  });

  return userMenuConfig?.payload?.loginLabel || 'Login';
};

export const getNavItemConfig = (
  activeProfile: Profile,
  isLoggedIn: boolean,
  label: string,
): HeaderNavigationItemsConfiguration & { subLabel: string | undefined }[] => {
  const { t } = useTranslations();
  const { businessType, displayName = '', id } = activeProfile;
  let fullName: string | undefined;
  let subLabel: string | undefined;

  if (isLoggedIn && displayName) {
    label = displayName;
    fullName = businessType === 'B2B' ? displayName.charAt(0) : displayName;
  } else if (isLoggedIn) {
    label = t('profile.context.company.registered');
  }

  if (businessType === 'B2C') {
    subLabel = t('profile.context.private.guest');
  }

  if (businessType === 'B2B' && !id?.includes('DEFAULT')) {
    subLabel = t('profile.context.company.guest');
  }

  return [
    {
      name: 'iam-user-menu-v3',
      label,
      subLabel,
      avatar: {
        fullName,
        icon: businessType === 'B2B' ? 'company/24' : 'bds/user/24',
      },
    },
  ];
};

import { isLoggedIn } from '@b2x/authentication-library';
import { CONNECTIONS, LOG_LEVEL, getConnectionState } from '@b2x/core/bundle';
import { initializeStore } from '@seamless/store';

import type { AuthenticationState } from '@b2x/authentication-library';
import type { ProfileState } from '@b2x/profile-library';
import type { HeaderDispatchers } from '@dh-io-hp/header-connection';

import { addUnloadEvent, getDispatchers } from '../utilities/common';
import { getLoginLabelFromUserMenuConfig, getNavItemConfig } from '../utilities/helpers';
import { LOG_GROUPS, logger } from '../utilities/logger';

export const handleNavigationItem = () => {
  const store = initializeStore();

  const subscription = store.subscribe(CONNECTIONS.PROFILE_CONNECTION_NAME, async (state: ProfileState) => {
    const authState = await getConnectionState<AuthenticationState>(CONNECTIONS.AUTH_CONNECTION_NAME);
    const dispatchers = await getDispatchers<HeaderDispatchers>('HP_HEADER_CONNECTION');
    const userMenuLoginLabel = await getLoginLabelFromUserMenuConfig();
    const navigationItemConfig = getNavItemConfig(state.activeProfile, isLoggedIn(authState), userMenuLoginLabel);

    dispatchers.setNavigationItemsConfiguration(navigationItemConfig);

    logger('Update OneHeader navigation item with', {
      args: [navigationItemConfig],
      group: LOG_GROUPS.NAVIGATION_ITEM,
      level: LOG_LEVEL.INFO,
    });
  });

  addUnloadEvent(subscription);
};
